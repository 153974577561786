<template>
<div class="user-index">
  <el-page-header @back="goBack" :content="title"></el-page-header>
  <div class="content">
    <h2 class="content-title">基本信息</h2>
    <el-form label-position="right" label-width="140px" :rules="rules" ref="ruleForm" class="form" :model="ruleForm">
      <el-input style="display:none;" v-model="ruleForm.password" placeholder="输入密码" show-password></el-input>
      <el-form-item label="用户名：" prop="username">
        <el-input v-model.number="ruleForm.username" placeholder="输入用户名"></el-input>
      </el-form-item>
      <el-form-item label="姓名：" prop="name">
        <el-input v-model="ruleForm.name" placeholder="​输入姓名"></el-input>
      </el-form-item>
      <!-- <el-form-item label="手机号：" prop="phone">
        <el-input v-model="ruleForm.phone" placeholder="​输入手机号"></el-input>
      </el-form-item> -->
      <el-form-item label="所属机构：" prop="organization">
        <el-select class="select" v-model="ruleForm.organization" filterable placeholder="请选择机构名称">
          <el-option v-for="item in organization" :key="item.organization_id" :label="item.name" :value="item.organization_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="角色：" prop="role">
        <el-select class="select" v-model="ruleForm.role" disabled filterable placeholder="请选择角色">
          <el-option label="机构管理员" :value="4">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="密码：" prop="password">
        <el-input v-model="ruleForm.password" type="password" autocomplete="new-password" placeholder="输入密码" show-password></el-input>
      </el-form-item>
      <p class="tip">默认设置初始密码为yili2024</p>
      <el-form-item label="确认密码：" prop="re_password">
        <el-input v-model="ruleForm.re_password" type="password" autocomplete="new-password" placeholder="确认密码" show-password></el-input>
      </el-form-item>
      <el-form-item label="状态：" prop="status">
        <el-switch v-model="ruleForm.status" active-text="开启" inactive-text="关闭"></el-switch>
      </el-form-item>
      <el-form-item v-if="type=='add'" label="是否为测试用户：" prop="status">
        <el-switch v-model="ruleForm.is_test" active-text="是" inactive-text="否"></el-switch>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm" :loading="submitLoading" :disabled="canSubmit">{{type=='add'?'立即创建':'保存'}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</div>
</template>

<script>
export default {
  name: "inspect-index",
  data() {
    var validatePass = (rule, value, callback) => {
      if (this.ruleForm.re_password !== "") {
        this.$refs.ruleForm.validateField("re_password");
      }
      callback();
    };
    var validatePass2 = (rule, value, callback) => {
      console.log(value, this.ruleForm.password);
      if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      type: '',
      id: "",
      title: "",
      submitLoading: false,
      ruleForm: {
        name: "",
        organization: "",
        username: "",
        password: "",
        re_password: "",
        role: "",
        status: true,
        is_test: false,
        phone: ''
      },
      rules: {
        name: [{
          required: true,
          message: "请输入姓名",
          trigger: "blur"
        }],
        username: [{
          required: true,
          message: "请输入用户名",
          trigger: "blur"
        }],
        organization: [{
          required: true,
          message: "请选择机构",
          trigger: "blur"
        }, ],
        mobile: [{
            required: true,
            message: "请输入手机号",
            trigger: "blur"
          },
          {
            type: "number",
            message: "手机号必须为数字值"
          },
        ],
        password: [{
          validator: validatePass,
          trigger: "blur"
        }, ],
        re_password: [{
          validator: validatePass2,
          trigger: "blur"
        }, ],
        role: [{
          required: true,
          message: "请选择角色",
          trigger: "blur"
        }],
        job: [{
          required: true,
          message: "请选择岗位",
          trigger: "blur"
        }],
      },
      organization: [],
      role: [],
      loading: false,
      createLoading: false,
      currentPage: 1,
    };
  },
  mounted() {
    let r = this.$route;
    let t = r.params.type;
    let id = r.query.id;
    this.type = t;
    if (t == "add") {
      this.title = "添加用户";
    } else if (t == "edit") {
      this.title = "编辑用户";
      this.id = id;
    }
    this.ruleForm.username = ''
    this.ruleForm.password = ''
    this.init();
  },
  computed: {
    canSubmit() {
      return !(this.ruleForm.name && this.ruleForm.organization && this.ruleForm.username && this.ruleForm.role);
    },
  },
  methods: {
    async init() {
      this.loading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/organ/userdetial",
        data: {
          id: this.id || 0
        }
      });
      this.loading = false;
      if (res.code == 0) {
        console.log(res)
        this.role = res.data.role;
        this.organization = res.data.organization;
        let data = res.data.list[0];
        console.log(data)
        if (this.type == 'edit') {
          this.ruleForm.name = data.name;
          this.ruleForm.username = data.username;
          this.ruleForm.phone = data.phone;
          this.ruleForm.status = data.status == 1 ? true : false;
          this.ruleForm.organization = data.organization_id;
          this.ruleForm.role = 4;
        } else {
          this.ruleForm.role = 4;
        }
        console.log(this.ruleForm)
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async submitForm() {
      this.submitLoading = true;
      let res = await this.$api({
        method: "post",
        url: "/api/organ/usersave",
        data: {
          id: this.type == 'edit' ? this.id : 0,
          username: this.ruleForm.username,
          password: this.ruleForm.password,
          // phone: this.ruleForm.phone,
          name: this.ruleForm.name,
          organization_id: this.ruleForm.organization,
          role_id: this.ruleForm.role,
          status: this.ruleForm.status ? 1 : 0,
          is_test: this.ruleForm.is_test ? 1 : 0,
        }
      });
      this.submitLoading = false;
      if (res.code == 0) {
        this.$message({message:this.type == 'add' ? '创建成功' : '保存成功',type:'success'});
        this.$router.go(-1);
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    getList() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
    deleteRow() {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
          customClass: "customConfirm",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonClass: "cancelButtonClass",
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    resetData() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
    create() {
      this.createLoading = true;
      setTimeout(() => {
        this.createLoading = false;
        this.$router.push("/inspect");
      }, 2000);
    },
    handleCurrentChange() {},
  },
  components: {
    // HelloWorld
  },
};
</script>

<style lang="scss" scoped>
.user-index {
  padding: 10px 4vw;
  min-height: calc(100vh - 150px);

  .title {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
  }

  .content {
    overflow: hidden;
    width: 100%;
    background: #fff;
    margin-top: 20px;
    padding: 0 2vw;
    min-height: calc(100vh - 220px);
    display: flex;
    flex-direction: column;

    .content-title {
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
    }

    .form {
      width: 60%;
    }

    .select {
      width: 100%;
    }
  }

  .tip {
    font-size: 14px;
    color: #666;
    padding-left: 140px;
    margin-bottom: 20px;
    margin-top: -20px;
  }
}
</style>

<style lang="scss">
.user-index {
  .el-form-item__content {
    text-align: left;
  }

  .el-form-item {
    margin-bottom: 32px;
  }

  .el-descriptions {
    font-size: 15px;
  }

  .el-descriptions-item__label:not(.is-bordered-label) {
    width: 80px;
    justify-content: flex-end;
    color: #000;
  }

  .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
    padding-bottom: 22px;
  }
}
</style>
